<template>
  <div :class="wrapperClassSetting" :style="{ width: full ? '100%' : '50%' }">
    <div
      v-if="!hideLabel"
      class="vx-col sm:w-1/3 w-full flex"
      style="min-width: 100px"
    >
      <label :for="filterLabel">{{ filterLabel }}</label>
    </div>
    <div :class="multiselectWrapperClass">
      <multiselect
        :id="filterLabel"
        v-model="selectedCustomer"
        :options="
          multiple
            ? this.$store.state.customerFilter.customers
            : this.$store.state.customerFilter.singleCustomers
        "
        :multiple="multiple"
        :allow-empty="true"
        :group-select="false"
        :max-height="120"
        :limit="3"
        placeholder="Select Customer"
        track-by="ID"
        label="name"
        @search-change="onAddTag"
        :disabled="disabled"
        :custom-label="queryCustomer"
        ref="tagInput"
      >
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">
              <span class="option__title"
                >({{ props.option.code }}) {{ props.option.name }}</span
              >
            </span>
          </div>
        </template>

        <template slot="tag" slot-scope="props">
          <div class="multiselect__tag">
            <span>{{ props.option.name }}</span>
            <i
              class="multiselect__tag-icon"
              @click.prevent
              @mousedown.prevent.stop="props.remove(props.option, $event)"
            />
          </div>
        </template>
      </multiselect>
      <vs-button
        class="ml-4"
        v-if="showClearButton"
        color="danger"
        icon-pack="feather"
        icon="icon-x"
        @click="onClear"
      />
    </div>
  </div>
  <!-- <vs-button
      class="ml-4"
      v-if="showClearButton"
      color="danger"
      icon-pack="feather"
      icon="icon-x"
    /> -->
</template>
<script>
import Multiselect from "vue-multiselect";
import debounce from "debounce";

// import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  props: {
    full: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filterLabel: {
      type: String,
      default: "Customer",
    },
    showClearButton: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    multiselect: Multiselect,
  },
  data() {
    return {
      // selectedCustomer: Object,
      wrapperClassSetting: "",
      multiselectWrapperClass: "",
      wrapperWidth: 0,
      labelClass: "",
      debounceFunction: null,
    };
  },
  mounted() {
    if (this.multiple) {
      const selected = this.$store.state.customerFilter.selectedCustomers;
      if (selected.length === 0) {
        this.$store.commit("customerFilter/setSelectedCustomers", [
          this.$store.state.customerFilter.customerAllOption,
        ]);
      }
    } else {
      const selected = this.$store.state.customerFilter.selectedCustomer;
      if (Object.keys(selected).length === 0) {
        this.$store.commit("customerFilter/setSelectedCustomer", {});
      }
    }
  },
  computed: {
    selectedCustomer: {
      get() {
        return this.$store.state.customerFilter.selectedCustomers;
      },
      set(value) {
        if (this.multiple) {
          if (value.length === 0) {
            this.$store.commit(
              "customerFilter/setSelectedCustomers",
              [this.$store.state.customerFilter.customerAllOption]
            );

            this.$store.dispatch("customerFilter/getAllCustomers");

            return;
          }

          if (value[0].ID === 0) {
            value.shift();
            this.$store.commit("customerFilter/setSelectedCustomers", value);
            this.selectedCustomer = value;
          } else if (value.some((cust) => cust.ID === 0)) {
            this.$store.commit("customerFilter/setSelectedCustomers", [
              this.$store.state.customerFilter.customerAllOption,
            ]);
          } else {
            this.$store.commit("customerFilter/setSelectedCustomers", value);
          }
        } else {
          this.$store.commit("customerFilter/setSelectedCustomer", value);
        }
      },
    },
  },
  created() {
    if (this.full) {
      this.wrapperClassSetting = "flex flex-col w-full gap-2";
      this.multiselectWrapperClass = "w-full whitespace-no-wrap";
      this.labelClass = "mb-2";
    } else {
      this.wrapperClassSetting = "vx-row flex flex-row items-center";
      this.multiselectWrapperClass =
        "vx-col sm:w-2/3 w-full whitespace-no-wrap";
      this.labelClass = "vx-col sm:w-1/3 w-full flex flex-col";
    }

    if (this.showClearButton) {
      this.multiselectWrapperClass += " flex gap-1";
    }
  },
  methods: {
    async onAddTag(tag) {
      if (!tag) {
        return;
      }

      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }

      const tags = tag.replace(/\s+/g, "").trim().split(";");

      if (tag.includes(";") && this.multiple) {
        const tagObjects = [];
        const length = this.$store.getters["customerFilter/getCustomersLength"];
        for (let i = 0; i < tags.length; i++) {
          const tagObject = {
            ID: length + 1,
            code: tags[i],
            name: tags[i],
          };
          tagObjects.push(tagObject);
        }
        this.$store.commit("customerFilter/setSelectedCustomers", tagObjects);
        this.$refs.tagInput.deactivate();
        this.debounceFunction = debounce(async () => {
          await this.getCustomerByCopyPaste();
        }, 500);

        this.debounceFunction();
      } else {
        this.debounceFunction = debounce(() => {
          this.getCustomer(tag);
        }, 500);
        // this.$refs.tagInput.deactivate();
        this.debounceFunction();
      }
    },

    onShow() {
      this.$emit("onShow");
    },
    queryCustomer({ name, code }) {
      if (!name || !code) {
        return "Select territory";
      } else if (name === "All") {
        return `(${code}), ${name}`;
      }
      return `(${code}), ${name}`;
    },
    async getCustomer(tag) {
      await this.$store.dispatch("customerFilter/getCustomers", {
        search: tag,
      });
    },
    async getCustomerByCopyPaste() {
      const customer_codes =
        this.$store.getters["customerFilter/getSelectedCustomersCodes"];
      await this.$store.dispatch("customerFilter/getCustomers", {
        customer_codes,
      });
    },
    onClear() {
      const length = this.$store.getters["customerFilter/getCustomersLength"];
      const cus = this.$store.state.customerFilter.selectedCustomers;
      if (length === 1 && cus[0].ID === 0) {
        return;
      }
      this.$store.commit("customerFilter/resetSelectedCustomers");
      this.$store.dispatch("customerFilter/getAllCustomers");
    },
  },
};

// onTerritoryChange
</script>
